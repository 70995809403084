// CustomLoginPage.js
import React, { useState } from 'react';
import { useLogin, Notification } from 'react-admin';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardActions, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuthContext } from '../App'; // Import the custom AuthContext
import theme from './ra_theme';

const PREFIX = 'CustomLogin';

const classes = {
  main: `${PREFIX}-main`,
  card: `${PREFIX}-card`,
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  actions: `${PREFIX}-actions`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.card}`]: {
    minWidth: 300,
  },
  [`& .${classes.form}`]: {
    padding: '1em 1em 1em 1em',
  },
  [`& .${classes.input}`]: {
    marginTop: '1em',
  },
  [`& .${classes.actions}`]: {
    padding: '0 1em 1em 1em',
  },
}));

const CustomLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    login({ email, password }).catch(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <div className={classes.main}>
          <Card className={classes.card}>
            <form onSubmit={submit}>
              <div className={classes.form}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  className={classes.input}
                />
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  Login
                </Button>
              </CardActions>
            </form>
          </Card>
          <Notification />
        </div>
      </Root>
    </ThemeProvider>
  );
};

export default CustomLoginPage;
