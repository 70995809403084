import React, { useEffect } from "react";
import {
	Admin,
	Resource,
	Layout,
	SimpleForm,
	TextInput,
	CustomRoutes,
} from "react-admin";
import { Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import { AppBar, UserMenu, Logout } from "react-admin";
import dataProvider from "./dataProvider";
import authProvider from "./authProvider";
import {
	UsersList,
	UsersEdit,
	UsersCreate,
	UsersShow,
} from "./components/ra_users";
import {
	ProjectsList,
	ProjectsEdit,
	ProjectsCreate,
	ProjectShow,
} from "./components/ra_projects";
import { EmailsList, EmailEdit } from "./components/ra_emails";
import CustomLoginPage from "./components/CustomLoginPage";
import { MenuItemLink } from "react-admin";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Work";
import EmailIcon from "@mui/icons-material/Email";
import UserProfileEdit from "./components/ra_userPage";
import WorkIcon from "@mui/icons-material/Work";
import SettingsIcon from "@mui/icons-material/Settings";
import BuildIcon from "@mui/icons-material/Build";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ProfileIcon from "@mui/icons-material/AccountCircle";
import { useAuthContext } from "./App";

const CustomLayout = ({ children }) => (
	<div style={{ padding: "2rem" }}>
		{" "}
		{/* Or your desired styling */}
		{children} {/* Only render the content */}
	</div>
);

const AdminApp = () => {
	return (
		<Admin
			layout={CustomLayout} // Apply the custom layout
			basename="/admin"
			dataProvider={dataProvider}
			authProvider={authProvider}
			loginPage={CustomLoginPage}
		>
			{/* Define your resources here */}
			{(permissions) => [
				permissions >= 99 ? (
					<Resource
						name="users"
						list={UsersList}
						edit={UsersEdit}
						create={UsersCreate}
						show={UsersShow}
						icon={PersonIcon}
					/>
				) : null,
				<Resource
					name="projects"
					list={ProjectsList}
					edit={permissions >= 98 ? ProjectsEdit : null}
					create={permissions >= 98 ? ProjectsCreate : null}
					show={ProjectShow}
					icon={GroupIcon}
				/>,
				permissions >= 98 ? (
					<Resource
						name="emails"
						list={EmailsList}
						edit={EmailEdit}
						icon={EmailIcon}
					/>
				) : null,
			]}
			{/* Custom Routes */}
			<CustomRoutes>
				<Route path="/profile" component={UserProfileEdit} />
				<Route path="/login" component={CustomLoginPage} />
			</CustomRoutes>
		</Admin>
	);
};

export default AdminApp;
