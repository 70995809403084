import React, { useState } from 'react';
import {
    TextInput,
    SimpleFormIterator,
    ArrayInput,
    Datagrid,
    TextField,
    NumberField,
    minValue,
    useDataProvider,
    useRefresh,
    useNotify,
    Button,
    FunctionField,
    useRecordContext,
    ArrayField,
    SimpleForm,
    required,
    NumberInput,
    BooleanField,

} from 'react-admin';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';




// TO BE USED INTO PROJECTS SHOW
export const SystemsShow = () => {
    return (
        <ArrayField source="systems">
            <Datagrid>
                <TextField source="systemName" label="System Name" />
                <NumberField source="massMultiplier" label="Mass Multiplier" />
                <BooleanField source="required" label="Required" />
                {/* Add other system fields as needed */}
            </Datagrid>
        </ArrayField>
    );
};
// TO BE USED INTO PROJECTS SHOW
export const SystemsTabInlineEdit = () => {
    const record = useRecordContext(); // The project or system containing the items
    const [editIndex, setEditIndex] = useState(null); // Track the row being edited
    const [editItem, setEditItem] = useState(null); // Track the current item being edited
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    // Log the record object for debugging
    // console.log('Record System:', record); // Check the 'record' in edit mode

    const handleEdit = (index, item) => {
        setEditIndex(index);
        setEditItem(item);
    };

    const handleSave = async () => {
        try {
            const updatedItems = record.items.map((item, index) =>
                index === editIndex ? editItem : item
            );

            await dataProvider.update('systems', {
                id: record.id,
                data: { ...record, items: updatedItems },
                previousData: record,
            });

            setEditIndex(null);
            setEditItem(null);
            refresh();
            notify('Item updated successfully', { type: 'info' });
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
        setEditItem(null);
    };

    // Safeguard to ensure `record.items` exists and is an array
    const systems = record?.systems ?? [];

    // Debugging Step: Log the items array
    // console.log('Items:', system); // <-- Check this in the browser's developer console

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>System Name</TableCell>
                    <TableCell>Total Mass</TableCell>
                    <TableCell>Total Price</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {systems.length > 0 ? (
                    systems.map((system, index) => (
                        <TableRow key={index}>
                            {editIndex === index ? (
                                <>
                                    <TableCell>
                                        <TextInput
                                            source="systemName"
                                            defaultValue={system.systemName}
                                            onChange={(e) => setEditItem({ ...editItem, systemName: e.target.value })}
                                            validate={required()}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberInput
                                            source="totalMass"
                                            defaultValue={system.totalMass}
                                            onChange={(e) => setEditItem({ ...editItem, totalMass: e.target.value })}
                                            validate={[required(), minValue(0)]}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberInput
                                            source="totalPrice"
                                            defaultValue={system.totalPrice}
                                            onChange={(e) => setEditItem({ ...editItem, totalPrice: e.target.value })}
                                            validate={[required(), minValue(0)]}
                                        />
                                    </TableCell>
                                    {/* <TableCell>
                                        <TextInput
                                            source="manufacturerId"
                                            defaultValue={item.manufacturerId}
                                            onChange={(e) => setEditItem({ ...editItem, manufacturerId: e.target.value })}
                                        />
                                    </TableCell> */}
                                    <TableCell>
                                        <Button onClick={handleSave} color="primary">Save</Button>
                                        <Button onClick={handleCancel}>Cancel</Button>
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell>
                                        <TextField source="systemName" record={system} />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField source="totalMass" record={system} />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField source="totalPrice" record={system} />
                                    </TableCell>
                                    {/* <TableCell>
                                        <TextField source="manufacturerId" record={system} />
                                    </TableCell> */}
                                    <TableCell>
                                        <Button onClick={() => handleEdit(index, system)} color="primary">Edit</Button>
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={5}>No items available</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export const SystemsTab = () => {
    const record = useRecordContext(); // The project containing the systems
    const [openSystemModal, setOpenSystemModal] = useState(false); // For system modal
    const [openItemsModal, setOpenItemsModal] = useState(false); // For items modal
    const [modalRecord, setModalRecord] = useState(null); // Track the selected system
    const [editMode, setEditMode] = useState(null); // To track if adding or duplicating system
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const sanitizeData = (data) => {
        if (Array.isArray(data)) {
            return data.map(sanitizeData);
        } else if (data && typeof data === 'object') {
            const sanitized = {};
            Object.keys(data).forEach((key) => {
                const value = data[key];
                sanitized[key] = value !== undefined ? sanitizeData(value) : null;
            });
            return sanitized;
        }
        return data;
    };

    const handleAddSystem = () => {
        setModalRecord({
            systemName: '',
            description: '',
            sumup: { sumup_mass: 0, sumup_price: 0 },
            items: []
        });
        setEditMode('add');
        setOpenSystemModal(true);
    };

    const handleDuplicateSystem = (system) => {
        const duplicatedSystem = { ...system, systemName: `${system.systemName} (Copy)` };
        setModalRecord(duplicatedSystem);
        setEditMode('duplicate');
        setOpenSystemModal(true);
    };

    const handleEditSystem = (system) => {
        setModalRecord(system);
        setEditMode('edit');
        setOpenSystemModal(true);
    };

    const handleEditItems = (system) => {
        setModalRecord(system);
        setOpenItemsModal(true);
    };

    const handleSaveSystem = async (updatedSystem, originalName) => {
        try {
            const updatedSystems = editMode === 'add'
                ? [...record.systems, updatedSystem]
                : record.systems.map((system) =>
                    system.systemName === originalName ? updatedSystem : system
                );

            const sanitized = sanitizeData({ ...record, systems: updatedSystems });
            await dataProvider.update('projects', {
                id: record.id,
                data: sanitized,
                previousData: record,
            });

            setOpenSystemModal(false);
            refresh();
            notify('System saved successfully', { type: 'info' });
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handleSaveItems = async (updatedItems) => {
        try {
            const updatedSystems = record.systems.map((system) =>{
                const totalMass1 = updatedItems.reduce((sum, item) => sum + (item.mass || 0), 0);
                const totalPrice1 = updatedItems.reduce((sum, item) => sum + (item.price || 0), 0);
                return system.systemName === modalRecord.systemName ? { ...modalRecord, items: updatedItems, totalMass:totalMass1,totalPrice:totalPrice1} : system

            }
            );

            await dataProvider.update('projects', {
                id: record.id,
                data: { ...record, systems: updatedSystems },
                previousData: record,
            });
            setOpenItemsModal(false);
            refresh();
            notify('Items updated successfully', { type: 'info' });
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handleCancel = () => {
        setOpenSystemModal(false);
        setOpenItemsModal(false);
        setModalRecord(null);
    };

    return (
        <>
            <Button onClick={handleAddSystem} variant="text" color="primary">
                Add System
            </Button>
            <ArrayField source="systems">
                <Datagrid>
                    <TextField source="systemName" label="System Name" />
                    {/* <NumberField source="sumup.sumup_mass" label="Total Mass" /> */}
                    <NumberField source="totalMass" label="Total Mass" />
                    {/* <NumberField source="sumup.sumup_price" label="Total Price" /> */}
                    <NumberField source="totalPrice" label="Total Price" />
                    <FunctionField
                        label="Actions"
                        render={(localRecord) => (
                            <>
                                <Button onClick={(e) => { e.stopPropagation(); handleEditSystem(localRecord); }} color="primary">
                                    Edit System
                                </Button>
                                <Button onClick={(e) => { e.stopPropagation(); handleDuplicateSystem(localRecord); }} color="primary">
                                    Duplicate System
                                </Button>
                                <Button onClick={(e) => { e.stopPropagation(); handleEditItems(localRecord); }} color="secondary">
                                    Edit Items
                                </Button>
                            </>
                        )}
                    />
                </Datagrid>
            </ArrayField>

            {/* Modal for Editing System */}
            {modalRecord && (
                <Dialog open={openSystemModal} onClose={handleCancel} fullWidth maxWidth="md">
                    <DialogTitle>
                        {editMode === 'add' ? 'Add System' : `Edit System: ${modalRecord.systemName}`}
                    </DialogTitle>
                    <DialogContent>
                        <SystemsModal
                            systemName={modalRecord.systemName}
                            system={modalRecord} // Pass the selected system to the modal
                            onSave={handleSaveSystem}
                            onCancel={handleCancel}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {/* Modal for Editing Items */}
            {modalRecord && (
                <Dialog open={openItemsModal} onClose={handleCancel} fullWidth maxWidth="md">
                    <DialogTitle>Edit Items for System: {modalRecord.systemName}</DialogTitle>
                    <DialogContent>
                        <ItemsModal
                            items={modalRecord.items || []} // Pass the system items to the modal
                            onSave={handleSaveItems}
                            onCancel={handleCancel}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export const SystemsModal = ({ system, onSave, onCancel, systemName }) => {
    const handleSubmit = (values) => {
        onSave(values, systemName);
    };

    return (
        <SimpleForm
            defaultValues={system}  // Ensure the form is populated with the system data
            onSubmit={handleSubmit}  // Trigger save on form submission
        >
            <TextInput source="systemName" label="System Name" validate={required()} fullWidth />
            <TextInput source="description" label="Description" multiline fullWidth />
            <NumberInput
                source="sumup.sumup_mass"
                label="Total Mass"
                defaultValue={0}
                validate={[required(), minValue(0)]}
                disabled
            />
            <NumberInput
                source="sumup.sumup_price"
                label="Total Price"
                defaultValue={0}
                validate={[required(), minValue(0)]}
                disabled
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button onClick={onCancel}>Cancel</Button>
                {/* <Button type="submit" color="primary">Save</Button> */}
            </div>
        </SimpleForm>
    );
};

export const ItemsModal = ({ items, onSave, onCancel }) => {
    const [editIndex, setEditIndex] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [itemList, setItemList] = useState(items); // Maintain the list of items

    // Edit item handler
    const handleEdit = (index, item) => {
        setEditIndex(index);
        setEditItem(item);
    };

    // Save individual item
    const handleSaveItem = () => {
        const updatedItems = itemList.map((item, index) =>
            index === editIndex ? editItem : item
        );
        setItemList(updatedItems);
        setEditIndex(null);
        setEditItem(null);
    };

    // Save all items
    const handleSaveAll = () => {
        onSave(itemList); // Save all items when done
    };

    // Cancel editing
    const handleCancelEdit = () => {
        setEditIndex(null);
        setEditItem(null);
    };

    // Handle adding a new item
    const handleAddItem = () => {
        const newItem = {
            itemName: '',
            mass: 0,
            price: 0,
            manufacturerId: '',
        };
        setItemList([...itemList, newItem]);
        setEditIndex(itemList.length);
        setEditItem(newItem);
    };

    // Handle duplicating an item
    const handleDuplicateItem = (item) => {
        const duplicateItem = { ...item };
        setItemList([...itemList, duplicateItem]);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                <Button onClick={handleAddItem} color="primary">
                    Add Item
                </Button>
                <Button onClick={() => handleDuplicateItem(editItem)} color="primary" disabled={editIndex === null}>
                    Duplicate Item
                </Button>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Item Index</TableCell> */}
                        <TableCell>Item Name</TableCell>
                        <TableCell>Mass</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Manufacturer ID</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {itemList.map((item, index) => (
                        <TableRow key={index}>
                            {editIndex === index ? (
                                <>
                                    <TableCell>
                                        <TextInput
                                            source="itemName"
                                            defaultValue={item.itemName}
                                            // onClick={(e) =>
                                            //     setEditItem({ ...editItem, itemName: e.target.value })
                                            // }
                                            onChange={(e) =>
                                                setEditItem({ ...editItem, itemName: e.target.value })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberInput
                                            source="mass"
                                            defaultValue={item.mass}
                                            onChange={(e) =>
                                                setEditItem({ ...editItem, mass: parseFloat(e.target.value) })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberInput
                                            source="price"
                                            defaultValue={item.price}
                                            onChange={(e) =>
                                                setEditItem({ ...editItem, price: parseFloat(e.target.value) })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextInput
                                            source="manufacturerId"
                                            defaultValue={item.manufacturerId}
                                            onChange={(e) =>
                                                setEditItem({ ...editItem, manufacturerId: e.target.value })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={handleSaveItem} color="primary">
                                            Save {editIndex}
                                        </Button>
                                        <Button onClick={handleCancelEdit}>Cancel</Button>
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell>{item.itemName}</TableCell>
                                    <TableCell>{item.mass}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                    <TableCell>{item.manufacturerId}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEdit(index, item)} color="primary">
                                            Edit
                                        </Button>
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={handleSaveAll} color="primary">
                    Save All
                </Button>
            </div>
        </>
    );
};
