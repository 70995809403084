import React from 'react';

function HoverRange({
    className,
    config,
    onChange,
    style,
    disabled,
    min = 0,
    max = 100,
    step = 1,
    value,
    BackgroundColor,
    ...props
}) {
    // State to handle hover
    const [isHovered, setIsHovered] = React.useState(false);
    const defaultColors = {
        secondary: "gray", // default background color
        darkSecondary: "black" // default hover background color
    };
    const colors = config?.colors || defaultColors;
    const backgroundColor = BackgroundColor || colors.secondary;

    // Function to convert hex color to rgba
    const hexToRgba = (hex, alpha, multi = 1) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${Math.min(255, Math.max(0, r * multi))}, ${Math.min(255, Math.max(0, g * multi))}, ${Math.min(255, Math.max(0, b * multi))}, ${alpha})`;
    };

    const shadowColorLight = hexToRgba(backgroundColor, 0.2);
    const hoverShadowColorLight = hexToRgba(backgroundColor, 1);
    const hoverShadowColorDark = hexToRgba(backgroundColor, 0.6, 0.4);

    const trackStyle = {
        background: '#e0e0e0',
        borderRadius: '10px',
        boxShadow: `inset 4px 4px 6px rgba(0, 0, 0, 0.2), inset -4px -4px 6px rgba(255, 255, 255, 0.7)`,
        height: '10px',
    };

    const thumbStyle = {
        background: disabled ? colors.slate : backgroundColor,
        borderRadius: '50%',
        boxShadow: `0px 4px 8px ${shadowColorLight}, 0px -2px 4px ${hoverShadowColorDark}`,
        width: '30px', // Larger pointer
        height: '30px',
        cursor: 'pointer',
        transition: 'box-shadow 0.15s ease-in-out',
    };

    const hoverThumbStyle = isHovered
        ? {
              boxShadow: `inset 0px 4px 8px ${hoverShadowColorLight}, inset 0px -2px 4px ${hoverShadowColorDark}`,
          }
        : {};

    return (
        <div
            className={`flex items-center justify-center ${className}`}
            style={{ ...style }}
        >
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                disabled={disabled}
                onChange={onChange}
                style={{
                    // Hide the default range input styles
                    appearance: 'none',
                    width: '100%',
                    height: '30px',
                    background: 'transparent',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="w-full h-2 bg-transparent cursor-pointer"
                {...props}
            />
            {/* Styling the background track and thumb */}
            <style jsx>{`
                input[type="range"]::-webkit-slider-runnable-track {
                    ${Object.entries(trackStyle)
                        .map(([key, value]) => `${key}: ${value};`)
                        .join(' ')}
                }
                input[type="range"]::-moz-range-track {
                    ${Object.entries(trackStyle)
                        .map(([key, value]) => `${key}: ${value};`)
                        .join(' ')}
                }
                input[type="range"]::-ms-track {
                    width: 100%;
                    height: 6px;
                    cursor: pointer;
                    background: transparent;
                    border-color: transparent;
                    color: transparent;
                }
                input[type="range"]::-ms-fill-lower,
                input[type="range"]::-ms-fill-upper {
                    ${Object.entries(trackStyle)
                        .map(([key, value]) => `${key}: ${value};`)
                        .join(' ')}
                }
                input[type="range"]::-webkit-slider-thumb {
                    ${Object.entries({ ...thumbStyle, ...hoverThumbStyle })
                        .map(([key, value]) => `${key}: ${value};`)
                        .join(' ')}
                    margin-top: -10px; /* Align thumb with track */
                }
                input[type="range"]::-moz-range-thumb {
                    ${Object.entries({ ...thumbStyle, ...hoverThumbStyle })
                        .map(([key, value]) => `${key}: ${value};`)
                        .join(' ')}
                }
                input[type="range"]::-ms-thumb {
                    ${Object.entries({ ...thumbStyle, ...hoverThumbStyle })
                        .map(([key, value]) => `${key}: ${value};`)
                        .join(' ')}
                }
            `}</style>
        </div>
    );
}

export default HoverRange;
