// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import { getAuth} from "firebase/auth";
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6_e9055iFr_HTqkW5BouuyWxI3mgDbl0",
  authDomain: "alphalyrae-design.firebaseapp.com",
  projectId: "alphalyrae-design",
  storageBucket: "alphalyrae-design.appspot.com",
  messagingSenderId: "378004756034",
  appId: "1:378004756034:web:91fbbe3af49232f948ffdb",
  measurementId: "G-Q4G83DRGP2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);


// const storeEmail = async (email) => {
//     try {
//       await addDoc(collection(db, "emails"), {
//         email: email,
//         timestamp: new Date()
//       });
//     } catch (e) {
//       console.error("Error adding document: ", e);
//     }
//   };

export { db,auth,app,firebaseConfig, analytics};
