// theme.js
import { createTheme } from '@mui/material/styles';
import config from '../assets/config'; // Import the config file

const theme = createTheme({
  palette: {
    primary: {
      main: config.colors.accent, // Replace with your primary color
    },
    secondary: {
      main: config.colors.secondary, // Replace with your secondary color
    },
  },
  // Add other theme configurations as needed
});

export default theme;
