import React from 'react';
import Card from './Card';
import Stack from './Stack';
import config from '../assets/config'; // Import the config file
import useResponsive from './useResponsive';
import LanguageSwitcher from './LanguageSwitcher';
function Footer() {
  const isMobile = useResponsive();
  return (
      <Stack direction="v" style={{height: isMobile ? `200px` : '200px'}} enableScrollButtons={false}>
            <LanguageSwitcher />

          <Card
            key={0}
            title={config.footerCardsData.title}
            description={config.footerCardsData.description}
            config={config}
            style={{backgroundColor:'rgba(255,255,255,0.95)',overflow:'hidden'}}
            onButtonClick={() => console.log('Clicked')}
            direction={'auto'}
            enableScrollButtons = {false}
            imageContainerStyle={{height: isMobile ? '0' : '30px', width: isMobile ? '0' : '30px'}}
            imageStyle={{boxShadow: ` 0px 0px 0px rgba(200, 200, 200,0.3)`,height: isMobile ? '0' : '90px', width: isMobile ? '0' : '90px'}}
          />

      </Stack>

    );
}

export default Footer;

// Repeat for Gallery.js and Contact.js with appropriate content changes
