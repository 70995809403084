import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { logEventWithLocale } from './logEvents'; // Import the logging function
function HoverButton({ className, children, config, onClick, icon, iconStyle, style, disabled, inset = true, logPress,logExtra, BackgroundColor, ...props }) {
    // State to handle hover
    const [isHovered, setIsHovered] = React.useState(false);
    const defaultColors = {
        secondary: "gray", // default background color
        darkSecondary: "black" // default hover background color
    };
    const colors = config.colors || defaultColors;
    const backgroundColor = BackgroundColor || colors.secondary;

    // Style configurations
    // Function to convert hex color to rgba
    const hexToRgba = (hex, alpha, multi = 1) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${Math.min(255, Math.max(0, r * multi))}, ${Math.min(255, Math.max(0, g * multi))}, ${Math.min(255, Math.max(0, b * multi))}, ${alpha})`;
    };

    // Calculate shadow colors
    const shadowColorLight = hexToRgba(backgroundColor, 0.2);
    const hoverShadowColorLight = hexToRgba(backgroundColor, 1);
    const hoverShadowColorDark = hexToRgba(backgroundColor, 0.6, 0.4);
    const hoverShadowColorLightGradient = hexToRgba('#909090', 1);
    const hoverShadowColorDarkGradient = hexToRgba("#909090", 0.6, 0.4);
    // Tailwind-based styles
    const standardTail = `
        px-4 py-2 rounded-full
        flex items-center gap-2
        text-white
        transition-all
        enabled:hover:text-white
        disabled:bg-slate-400
        disabled:cursor-not-allowed
        transition
        duration-150
        ease-in-out
        active:translate-y-1
        motion-reduce:transition-none
    `;

    // Check if the BackgroundColor is a gradient
    const isGradient = Array.isArray(BackgroundColor) && BackgroundColor.length > 1;

    // Define background style
    const buttonStyle = {
        background: isGradient
            ? `linear-gradient(45deg, ${BackgroundColor.join(", ")})`
            : disabled
            ? colors.slate
            : backgroundColor,
        boxShadow: `-2px -2px 5px ${shadowColorLight}, 2px 2px 5px ${hoverShadowColorDark}`,
    };

    const hoverStyle = isHovered
        ? {
              boxShadow: isGradient ? `inset -2px -2px 5px ${hoverShadowColorLightGradient}, inset 2px 2px 5px ${hoverShadowColorDarkGradient}` : `inset -2px -2px 5px ${hoverShadowColorLight}, inset 2px 2px 5px ${hoverShadowColorDark}`,
          }
        : {};
    const handleClick = (e) => {
        if (logPress) {
            logEventWithLocale(logPress,logExtra); // Log the button press with the provided event name
        }
        if (onClick) {
            onClick(e); // Call the provided onClick function if it exists
        }
    };
    return (
        <button
            disabled={disabled}
            className={standardTail + className}
            style={{ ...buttonStyle, ...hoverStyle, ...style }}
            onClick={
                handleClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {icon && (
                <FontAwesomeIcon
                    icon={icon}
                    style={{
                        marginRight: '0px',
                        marginLeft: '0px',
                        width: '20px',
                        ...iconStyle,
                    }}
                />
            )}
            {children}
        </button>
    );
}

export default HoverButton;
