// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { motion } from 'framer-motion';
// import { useTranslation } from 'react-i18next';
// function MultiSelector({ isMobile, config, routes,style, CapsuleStyle,onSelect,ActiveButtonStyle,depth}) {

//     const navigate = useNavigate();
//     const { t } = useTranslation();
//     const location = useLocation(); // Get current location object   routes.findIndex(r => r.path === route.path);
// const [activeRoute, setActiveRoute] = useState(
//     routes[routes.findIndex(r => r.path.split('/')[depth] === location.pathname.split('/')[depth])] || location.pathname); // Set initial active route based on current path
// const [activeRouteName1, setActiveRouteName1] = useState(
//     depth > 1 ? null :  routes[routes.findIndex(r => {

//         return r.path.split('/')[depth] === location.pathname.split('/')[depth]
//     })].label || null); // Set initial active route based on current path
// const [activeRouteName, setActiveRouteName] = useState(() => {
//         if (depth > 1) {
//             return null;
//         }

//         const routeIndex = routes.findIndex(r => {
//             return r.path.split('/')[depth] === location.pathname.split('/')[depth];
//         });
//         // Check if a valid route index was found
//         if (routeIndex !== -1 && routes[routeIndex].label) {
//             return routes[routeIndex].label;
//         }

//         // If no valid route is found, return null
//         return null;
//     });
//     const refs = useRef(routes.map(() => React.createRef()));
//     const [capsuleStyle, setCapsuleStyle] = useState();

//     const buttonStyle = {
//         padding:'10px',
//         width:'100%',
//         fontSize: '16px',
//         color: 'rgb(29, 59, 82)',
//         backgroundColor: 'rgba(0,0,0,0)',
//         border: 'none',
//         borderRadius: '20px',
//         cursor: 'pointer',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         transition: 'color 0.3s ease',  // Smooth transition for background color
//     };

//     const activeButtonStyle = {
//         ...buttonStyle,
//         color: 'white' ,
//         ...ActiveButtonStyle // And perhaps a different text color
//     };

//     useEffect(() => {
//         updateCapsulePosition(activeRoute);
//     }, [activeRoute, isMobile,activeRouteName]);

//     const handleNavigation = (route) => {
//         setActiveRouteName(route.label);
//         onSelect(route.path);
//         navigate(route.path);
//         setActiveRoute(route);
//     };

//     const updateCapsulePosition = (route) => {
//         const index = routes.findIndex(r => r.path === route.path);
//         const ref = refs.current[index];

//         if (ref && ref.current) {
//             const { offsetWidth, offsetLeft, offsetTop } = ref.current;
//             setCapsuleStyle({
//                 width: `${offsetWidth}px`,
//                 left: isMobile ? '' : `${offsetLeft}px`,
//                 top: isMobile ? `${offsetTop}px` : '',
//             });
//         }
//     };
//     const standardTail = `
//     absolute
//     p-[10px] rounded-full
//     items-center
//     text-white
//     shadow-[-2px_-2px_5px_rgba(214,_255,_111,_0.2),_2px_5px_5px_rgba(79,_94,_41,_0.4)]
//     transition-all
//     bg-secondary
//     enabled:hover:shadow-[inset_-2px_-2px_5px_rgba(214,_255,_111,_1),inset_2px_2px_5px_rgba(79,_94,_41,_0.4)]
//     enabled:hover:text-white
//     disabled:bg-slate-400
//     disabled:cursor-not-allowed
//     transition
//     duration-700
//     ease-in-out
//     active:translate-y-1
//     motion-reduce:transition-none
// `;
//     return (
//         <div className={`flex
//         ${isMobile ? 'flex-col' : 'flex-row'} min-w-[200px]
//         justify-center items-center transition-all duration-300 rounded-[25px] relative
//         shadow-[inset_-2px_-2px_5px_rgba(149,_208,_255,0.3),inset_2px_2px_5px_rgba(0,_0,_0,_0.4)]`} >
//             {activeRouteName && (
//                 <motion.button
//                     className={standardTail}
//                     style={{ ...capsuleStyle, ...CapsuleStyle }}
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     onClick={() => handleNavigation(activeRoute)}
//                     >
//                     {activeRouteName}
//                 </motion.button>
//             )}
//             {routes.map((route, index) => (
//                 <button
//                     key={route.path}
//                     ref={refs.current[index]}
//                     className='text-[16px]  min-h-[44px]'
//                     style={activeRoute === route.path ? activeButtonStyle : buttonStyle}
//                     onClick={() => handleNavigation(route)}
//                 >
//                     {(!route.icon && !route.image) && t(route.label)}
//                     {(route.icon && <FontAwesomeIcon className="px-5" icon={route.icon} />)}
//                     {(route.image && <img src={route.image} alt={route.label} className="w-5 h-5 object-cover rounded-full" />)}

//                 </button>
//             ))}
//         </div>
//     );
// }

// export default MultiSelector;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function MultiSelector({
	isMobile,
	config,
	routes,
	style,
	CapsuleStyle,
	onSelect,
	ActiveButtonStyle,
	depth,
}) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation(); // Get current location
	const [activeRoute, setActiveRoute] = useState(
		routes.find(
			(r) => r.path.split("/")[depth] === location.pathname.split("/")[depth]
		) || location.pathname
	);
	// const [activeRouteName, setActiveRouteName] = useState(() => {
	// 	const route = routes.find(
	// 		(r) => r.path.split("/")[depth] === location.pathname.split("/")[depth]
	// 	);
	// 	return route ? route.label : null;
	// });
	const [activeRouteName, setActiveRouteName] = useState(() => {
		if (depth > 1) {
			return null;
		}

		const routeIndex = routes.findIndex((r) => {
			return r.path.split("/")[depth] === location.pathname.split("/")[depth];
		});
		// Check if a valid route index was found
		if (routeIndex !== -1 && routes[routeIndex].label) {
			return routes[routeIndex].label;
		}

		// If no valid route is found, return null
		return null;
	});
	const [capsuleStyle, setCapsuleStyle] = useState({});
	const buttonStyle = {
		padding: "10px",
		width: "100%",
		fontSize: "16px",
		color: "rgb(29, 59, 82)",
		backgroundColor: "rgba(0,0,0,0)",
		border: "none",
		borderRadius: "20px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: "color 0.3s ease", // Smooth transition for background color
	};
	const activeButtonStyle = {
		...buttonStyle,
		color: "white",
		...ActiveButtonStyle,
	};

	// Handle route change and capsule positioning
	const handleNavigation = (route, event) => {
		setActiveRouteName(route.label);
		onSelect(route.path);
		if (!route.menu) {
			navigate(route.path);
		}
        if(route.action){
            route.action();
        }
		setActiveRoute(route);
		// Update capsule position
		const { offsetWidth, offsetLeft, offsetTop } = event.target;
		setCapsuleStyle({
			width: `${offsetWidth}px`,
			left: isMobile ? "" : `${offsetLeft}px`,
			top: isMobile ? `${offsetTop}px` : "",
		});
	};

	// Update capsule position on initial load or route change
	useEffect(() => {
		const activeIndex = routes.findIndex((r) => r.path === activeRoute.path);
		const activeElement = document.getElementById(`button-${activeIndex}`);

		if (activeElement) {
			const { offsetWidth, offsetLeft, offsetTop } = activeElement;
			setCapsuleStyle({
				width: `${offsetWidth}px`,
				left: isMobile ? "" : `${offsetLeft}px`,
				top: isMobile ? `${offsetTop}px` : "",
			});
		}
	}, [activeRoute, isMobile]);

	return (
		<div
			className={`flex ${isMobile ? "flex-col" : "flex-row"} min-w-[200px]
            justify-center items-center transition-all duration-300 rounded-[25px] relative
            shadow-[inset_-2px_-2px_5px_rgba(149,_208,_255,0.3),inset_2px_2px_5px_rgba(0,_0,_0,_0.4)]`}
		>
			{activeRouteName && (
				<motion.button
					className="absolute p-[10px] rounded-full items-center text-white shadow-md transition-all bg-secondary"
					style={{ ...capsuleStyle, ...CapsuleStyle }}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					onClick={(event) => handleNavigation(activeRoute, event)}
				>
					{activeRouteName}
				</motion.button>
			)}

			{routes.map((route, index) => (
				<button
					key={route.path}
					id={`button-${index}`} // Add an id for reference
					className="text-[16px] min-h-[44px]"
					style={
						activeRoute.path === route.path ? activeButtonStyle : buttonStyle
					}
					onClick={(event) => handleNavigation(route, event)} // Pass event to update capsule
				>
					{!route.icon && !route.image && t(route.label)}
					{route.icon && <FontAwesomeIcon className="px-5" icon={route.icon} />}
					{route.image && (
						<img
							src={route.image}
							alt={route.label}
							className="w-5 h-5 object-cover rounded-full"
						/>
					)}
				</button>
			))}
		</div>
	);
}

export default MultiSelector;
