// src/components/users.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    EmailField,
    FunctionField,
} from 'react-admin';
import { format } from 'date-fns';
export const EmailsList = (props) => (
    <List {...props} title="Users">
        <Datagrid rowClick="edit">
            {/* <TextField source="id" label="ID" /> */}
            <EmailField source="email" label="Email" />
            <TextField source="language" label="Language" />
            <FunctionField
                label="Timestamp"
                render={record =>
                    record.timestamp
                        ? format(new Date(record.timestamp.seconds * 1000), 'Pp')
                        : ''
                }
            />
            {/* Add other fields as needed */}
        </Datagrid>
    </List>
);

export const EmailEdit = (props) => (
    <Edit {...props} title="Edit Email">
        <SimpleForm>
            <TextInput source="email" label="Email" />
            <TextInput source="language" label="Language" />
            <TextInput
                    source="timestamp"
                    label="Timestamp"
                    format={(v) =>
                        v ? format(new Date(v.seconds * 1000), 'yyyy-MM-dd HH:mm:ss') : ''
                    }
                    disabled
                />
        </SimpleForm>
    </Edit>
);
