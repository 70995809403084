import React from 'react';
import Card from './Card';
import Stack from './Stack';
import config from '../assets/config'; // Import the config file
import HoverCard from './HoverCard';
import HoverButton from './HoverButton';
import useResponsive from './useResponsive';
import usePublic from './usePublicConfig';
import { useTranslation } from 'react-i18next';
import { faHouse,faGlobe,faPhone, faAt} from '@fortawesome/free-solid-svg-icons'; // Example icon, replace with the one you need
import {faFacebook, faInstagram, faWhatsapp, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { maxHeight } from '@mui/system';
function ContactPage({peoplePath}) {
    const peopleFile = usePublic(peoplePath);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const people_row = peopleFile.team != undefined ? peopleFile.team : [];
    const people = people_row.filter(person => person.contactable == true);
    const isMobile = useResponsive();
    return (
        <Stack
        direction="h"
        style={{height:`calc(100vh - 250px)`}}
        titleLevel='h1'
        titlestyle={{color:"white"}}
        enableScrollButtons='true'
        config={config}
        >
            {people != [] && people.map(card => (

                // style={{backgroundColor:'rgba(255,255,255,0.95)',...(isMobile ? { minWidth: '90vw',display: 'flex',justifyContent:'center',alignItems:'center'} : {})}}
                // onButtonClick={() => console.log('Clicked', card.title)}

                // //imageStyle={{maxWidth: isMobile ? '35vh' : '300px',...(isMobile ? { maxWidth: '30vh',margin:'auto'} : {})}}
                // //itemsStyle={{margin:'auto',width:'100%',height:'100%',display:'flex'}}
                // imageStyle={{maxWidth: isMobile ? '35vh' : '200px',...(isMobile ? { width: '100%',margin:'auto'} : {})}}
                // itemsStyle={{margin:'auto',width:'100%',height:'100%',display:'flex'}}
                // />
                <Card
                key={card.id}
                image={`${process.env.PUBLIC_URL}/content/people/${card.image}`}
                title={card.title}
                titleLevel='h1'
                config={config}
                style={{backgroundColor:'rgba(255,255,255,0.95)',...(isMobile ? { minWidth: '90vw',display: 'flex',justifyContent:'center',alignItems:'center'} : {})}}
                buttonText= {t(card.buttonText)}
                onButtonClick={() => navigate(`/projects/${card.nameid}`)}
                direction={'v'}
                imageStyle={{aspectRatio: '3/4', height: '100%',maxWidth: isMobile ? '90vw' : '300px',...(isMobile ? { width: '100%', height: '100%', margin:'auto',maxHeight :'30vh'} : {})}}
                itemsStyle={{margin:'auto',width:'100%',height:'100%',display:'flex',padding:'10px'}}


            >
                <Stack direction='h'>
                    {card.socials !=[] && card.socials.map(social=>{
                        const iconstyle = {width:'20px',height:'20px'}
                        const style = {borderRadius:'10px',aspectRatio:'1',margin:"5px"}
                        if(social.type == "facebook")
                        {
                            return <HoverButton config={config} icon={faFacebook} iconStyle={iconstyle} BackgroundColor={['#a9c9ff', '#1877F2', '#0038A8']} style={style} onClick={() => (window.location.href = social.link)}/>
                        }
                        if(social.type == "web")
                        {
                            return <HoverButton config={config} icon={faGlobe} iconStyle={iconstyle} BackgroundColor={'#7b88a1'} style={style} onClick={() => (window.location.href = social.link)}/>
                        }
                        if(social.type == "instagram")
                        {
                            return <HoverButton config={config} icon={faInstagram} iconStyle={iconstyle} BackgroundColor={['#f09433', '#e6683c', '#dc2743', '#cc2366', '#bc1888']} style={style} onClick={() => (window.location.href = social.link)}/>
                        }
                        if(social.type == "email")
                        {
                            return <HoverButton config={config} icon={faAt} iconStyle={iconstyle} BackgroundColor={['#8ec63b', '#1e88e5']} style={style} onClick={() => (window.location.href = `mailto:${social.link}`)}/>
                        }
                        if(social.type == "linkedin")
                            {
                                return <HoverButton config={config} icon={faLinkedin} iconStyle={iconstyle} BackgroundColor={['#8db9e3', '#0077B5', '#004182']} style={style} onClick={() => (window.location.href = social.link)}/>
                            }
                        if(social.type == "phone")
                        {
                            return <HoverButton config={config} icon={faPhone} iconStyle={iconstyle} BackgroundColor={'#8ec63b'} style={style} onClick={() => (window.location.href = `tel:${social.link}`)}/>
                        }
                    })}
                </Stack>
            </Card>
            ))}
        </Stack>
    );
}

// function ProjectsPage({projectsPath}) {
//     const projects = usePublic(projectsPath).filter(project => project.visibility);
//     const navigate = useNavigate();
//     const isMobile = useResponsive();
//     const { t } = useTranslation();
//     return (
//     <Stack
//     direction="h"
//     style={{height:`calc(100vh - 250px)`}}
//     titleLevel='h1'
//     titlestyle={{color:"white"}}
//     enableScrollButtons='true'
//     config={config}>
//         {projects.map(card => (
//             <Card
//                 key={card.id}
//                 image={`${process.env.PUBLIC_URL}/content/projects/${card.nameid}/${card.image}`}
//                 title={card.title}
//                 description={card.description}
//                 config={config}
//                 style={{backgroundColor:'rgba(255,255,255,0.95)',...(isMobile ? { minWidth: '90vw',display: 'flex',justifyContent:'center',alignItems:'center'} : {})}}
//                 buttonText= {t(card.buttonText)}
//                 onButtonClick={() => navigate(`/projects/${card.nameid}`)}
//                 direction={'v'}
//                 imageStyle={{aspectRatio: '4/3' ,maxWidth: isMobile ? '60vh' : '600px',...(isMobile ? { width: '100%',margin:'auto'} : {})}}
//                 itemsStyle={{margin:'auto',width:'100%',height:'100%',display:'flex',padding:'10px'}}
//                 notificationImage={require(`../assets/${card.filterImage}`)}

//             />

//         ))}
//     </Stack>
//     );
// }

export default ContactPage;
