import React,{useState} from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import config from '../assets/config';
import Button from './Button';
import HoverButton from './HoverButton';
import Stack from './Stack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase"; // Ensure Firebase is initialized

const Modal = ({ isOpen, onClose, onSubmit, email, setEmail, error }) => {
    if (!isOpen) return null;

    return (
        <div style={modalStyles.overlay}>
        <div style={modalStyles.modal}>
            <h2>Enter your email to download the brochure</h2>
            <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={modalStyles.input}
            />
            {error && <p style={{ color: 'red' }}>Please enter a valid email</p>}
            <div style={modalStyles.buttonGroup}>
            <HoverButton
                style={modalStyles.button}
                onClick={onSubmit}
                config={config}
            >
                Submit
            </HoverButton>
            <HoverButton
                style={modalStyles.button}
                onClick={onClose}
                config={config}
            >
                Close
            </HoverButton>
            </div>
        </div>
        </div>
    );
    };



const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        width: '400px',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '10px',
    },
    button: {
        margin: '0 5px',
    },
    };







const MarkdownComponent = ({ markdown }) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation(); // Get the current language using i18next
    const currentLang = i18n.language; // Current language code (e.g., 'en', 'it', 'es')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(''); // New state for dynamic URL
    //   console.log(currentLang);
    // Function to parse custom styles within a given array of content parts
    const parseCustomStyles = (parts) => {
        const elements = [];
        let isStyling = false;
        let styleColor = null;

        parts.forEach((part, index) => {
        if (typeof part === 'string') {
            const matches = part.split(/(\?[#][0-9A-Fa-f]{6}\?)/);
            matches.forEach((match) => {
            if (match.match(/\?([#][0-9A-Fa-f]{6})\?/)) {
                if (!isStyling) {
                styleColor = match.slice(1, -1); // Extract color and start styling
                isStyling = true;
                } else {
                isStyling = false; // End styling on second marker
                }
            } else if (isStyling) {
                elements.push(<span key={`${index}-${match}`} style={{ color: styleColor }}>{match}</span>);
            } else {
                elements.push(match);
            }
            });
        } else {
            elements.push(part); // Non-string content is passed through unchanged
        }
        });

        return elements;
    };

 // Function to validate email
    const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
    };

    // Function to submit email and trigger download
    const handleSubmit = async () => {
        if (validateEmail(email)) {
            setError(false);
            try {
            await addDoc(collection(db, "emails"), {
                email: email,
                timestamp: new Date(),
                language: currentLang
            });
            // Trigger the download
            const link = document.createElement('a');
            link.href = downloadUrl; // Use the dynamic download URL
            link.download = downloadUrl.split('/').pop(); // Automatically name the file based on the URL
            link.click();
            setIsModalOpen(false); // Close modal after successful email submission
            } catch (e) {
            console.error("Error adding email: ", e);
            }
        } else {
            setError(true);
        }
    };
    const handleSubmitPreview = async () => {
        if (validateEmail(email)) {
            setError(false);
            try {
                // Add email to Firestore
                await addDoc(collection(db, "emails"), {
                    email: email,
                    timestamp: new Date(),
                    language: currentLang
                });

                // Trigger the PDF preview
                const link = document.createElement('a');
                link.href = downloadUrl; // Use the dynamic download URL
                link.target = '_blank'; // Open in a new tab for preview
                link.rel = 'noopener noreferrer'; // Security measure
                link.click(); // Open the file in a new tab for preview

                setIsModalOpen(false); // Close modal after successful email submission
            } catch (e) {
                console.error("Error adding email: ", e);
            }
        } else {
            setError(true);
        }
    };
    // Function to handle opening the modal
    const openModal = (url) => {
        setDownloadUrl(url); // Set the dynamic URL based on the button clicked
        setIsModalOpen(true);
    };

    // Function to handle closing the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setEmail(''); // Reset email field
        setError(false); // Clear any error messages
    };


  // Custom renderer for paragraph elements to include our style parser
    const Paragraph = ({ children,filter=''}) => {
        // Convert children to an array and process for custom styles
        const childrenArray = React.Children.toArray(children);
        const styledChildren = parseCustomStyles(childrenArray);
        return <p>{styledChildren}</p>;
    };
  // Custom Table Components
    const Table = ({ children }) => <div style={{
        backgroundColor:config.colors.secondary,
        borderRadius:'20px',
        padding:'2px',
        margin:'5px',
        }}>
            <table style={{
                width: '100%',
                overflow:'hidden',
                borderRadius:'20px',
                borderCollapse:'collapse'
        }}>{children}
        </table></div>;
    const TableHead = ({ children }) => <thead>{children}</thead>;
    const TableBody = ({ children }) => <tbody style={{borderColor:'inherit'}}>{children}</tbody>;
    const TableRow = ({ children }) => <tr style={{borderBottom: '1px solid #ececec' }}>{children}</tr>;
    const H1 = ({ children }) => {
        const color = children.split('#')[1] || 'black';
        const text = children.split('#')[2] || '';
        return <h1 style={{color:`#${color}` }}>{text}</h1>
    };
    const TableCell = ({ children, isHeader }) => {
        if(children === '!null!'){
        return;
        }
        // Extract attribute value and clean text
        const extractAndCleanAttribute = (text, pattern) => {
            const regex = new RegExp(pattern, 'i'); // Case insensitive match
            const matches = regex.exec(text);
            const value = matches && matches[1] ? parseInt(matches[1], 10) : 1;
            const cleanedText = text.replace(regex, ''); // Remove the attribute from text
            return [value, cleanedText];
        };

        // Process children to extract attributes and clean up text
        let cleanedChildren = children;
        let colspan = 1;
        let rowspan = 1;

        if (typeof children === 'string') { // Ensure children is a string before processing
            [colspan, cleanedChildren] = extractAndCleanAttribute(children, /colspan=['"]?(\d+)['"]?/);
            [rowspan, cleanedChildren] = extractAndCleanAttribute(cleanedChildren, /rowspan=['"]?(\d+)['"]?/);
        }

        const cellStyle = {
            padding: '8px 16px',
            border: `1px solid ${config.colors.secondary}`,
            backgroundColor: isHeader ? config.colors.secondary : 'white'
        };

        // Select tag based on header status
        const CellTag = isHeader ? 'th' : 'td';
        const cellProps = {
            style: cellStyle,
            colSpan: colspan !== 1 ? colspan : undefined, // Only add attribute if not default
            rowSpan: rowspan !== 1 ? rowspan : undefined
        };

        return <CellTag {...cellProps}>{cleanedChildren}</CellTag>;
    };
    const isExternalUrl = (url) => {
        return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('www.');
    };
    const handleNavigation = (url) => {
        if (isExternalUrl(url)) {
            // If the URL is external, use window.location to navigate
            window.location.href = url;
        } else {
            // If the URL is internal, use the navigate function from React Router
            navigate(url);
        }
    };
  // Button renderer, assuming links with "button:" are buttons
    const ButtonRenderer = ({ node, ...props }) => {
        // if (props.children.startsWith("button")) {
        // const buttonType = props.children.startsWith("button") ? "button" :
        // props.children.startsWith("download-email") ? "download-email" : "download";
        const buttonType = props.children.split(':')[0];
        const buttonProp = props.children.split(buttonType)[1]; // Strip "button:" prefix
        const buttonText = props.children.split(':')[1]; // Strip "button:" prefix
        const buttonStyle = buttonProp.split(':')[0]; // Strip "button:" prefix
        const buttonUrl = props.href; // Strip "button:" prefix
        // console.log("BUTTONTYPE", buttonType)
        // console.log("BUTTONPROP", buttonProp)
        // console.log("BUTTONSTYLE", buttonStyle)
        if (buttonType === "button") {
            switch(buttonStyle){
                case '-inline-small' : return <HoverButton className={'inline-flex'} style={{margin:'5px'}} config={config} onClick={() => handleNavigation(buttonUrl)}>{buttonText}</HoverButton>;
                case '-inline' :       return <HoverButton className={'inline-flex'} style={{display: '',margin:'5px'}} config={config} onClick={() =>handleNavigation(buttonUrl)}>{buttonText}</HoverButton>;
                default:
                    return (
                    <HoverButton className={'bg-red'} style={{margin: '5px'}} config={config} onClick={() => handleNavigation(buttonUrl)}>
                        {buttonText}
                    </HoverButton>
                    );
            }
        }
        if (buttonType === "download") {
            return (
                // <a href={buttonUrl} style={{ textEmphasis: 3, textDecoration: 'underline' }}>
                // <HoverButton className={'inline-flex'} style={{margin: '5px'}} config={config} logPress={`Download`} logExtra={{buttonUrl}} onClick={() => null}>
                //         {buttonText}
                //     </HoverButton>
                // </a>
                <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
                <HoverButton className={'inline-flex'} style={{ margin: '5px' }} config={config} logPress={`Download`} logExtra={{ buttonUrl }} onClick={() => null}>
                    {buttonText}
                </HoverButton>
            </a>
            );
        }
        if (buttonType === "download-email") {
            return (
              <HoverButton className={'inline-flex'} style={{ margin: '5px' }} config={config} logPress={`Download-Mail`} logExtra={{buttonUrl}} onClick={() => openModal(buttonUrl)}>
                {buttonText}
              </HoverButton>
            );
          }


        // }
        return (
            <a href={props.href} target="_blank" rel="noopener noreferrer" style={{ textEmphasis: 3, color: config.colors.secondary, textDecoration: 'underline' }}>
                {props.children}
            </a>
            );


    };
    const CustomHr = ({ node, ...props }) => {
        // You can add more sophisticated logic to determine the style based on additional props
        let style = {};
        switch (node.type) {
        case 'thematicBreak_***':
            style = { borderTop: '3px dotted #ccc' };
            break;
        case 'thematicBreak_---':
            style = { borderTop: '3px solid #333' };
            break;
        case 'thematicBreak___':
            style = { borderTop: '3px dashed #666' };
            break;
        default:
            style = { borderTop: '1px solid #a9d734' }; // Default style
        }

        return <hr style={{width:"80%", margin:'auto' ,...style}} />;
    };
    const CustomImg = ({ src, alt }) => {
        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px' }} />
                {alt && alt != 'img' && <p style={{ color: '#666', fontSize: '14px' }}>{alt}</p>} {/* Optional caption */}
            </div>
        );
    };

    const preprocessMarkdown = (markdown) => {
    // Split the markdown into sections based on horizontal rules with specific annotations

    const sections = markdown.trim().replace(/[ \t]+/g, ' ').split('---v');
    let processedSections = [];
    let currentFlexDirection = 'row'; // Default flex direction

    sections.map(section => {
        processedSections.push({ content: section, flexDirection: currentFlexDirection });
    });


    processedSections.map((section, index) => {
        let processedSections2 = [];

        const localsections = section.content.trim().replace(/[ \t]+/g, ' ').split(/^---/gm);
        // console.log("LOCALSECTIONS",localsections);
        localsections.forEach((localsection, localsectionIndex) => {

            const localMatch = localsection.match(/local\((.*?)\)/);
            // console.log("LOCALMATCH",localMatch);
            // console.log("localsections",localsection);

            if (localMatch) {
                const targetLang = localMatch[1]; // Extract the language code (e.g., 'it', 'en')
                // console.log("targetLang", targetLang,"          currentLang", currentLang);

                if (currentLang.startsWith(targetLang)) {
                    const nextSection = localsection.replace(localMatch[0], '') || '';
                    processedSections2.push(nextSection); // Add the localized content
                }
            }else{
                let nextSection = '';
                if(localsectionIndex != 0){
                    nextSection = "---\n" + localsection || '';
                }else{
                    nextSection = localsection || '';
                }

                processedSections2.push(nextSection); // Add the localized content
            }
        });

        // Only process sections that are meant to be displayed for the current language
        processedSections[index].content = processedSections2.join('\n');
    });





    return processedSections;
    };

    const processText = (text) => {
    let returnString = { alignment: 'start', text: '' };
    if (!text) return returnString;  // Default return if no text

    const alignments = [
        { prefix: '[TA-CENTER]', alignment: 'center' },
        { prefix: '[TA-START]', alignment: 'start' },
        { prefix: '[TA-END]', alignment: 'end' }
    ];
    const sections = [
        { prefix: '[DESCRIPTION]', alignment: 'center' },
        { prefix: '[TA-START]', alignment: 'start' },
        { prefix: '[TA-END]', alignment: 'end' }
    ];

    for (const { prefix, alignment } of alignments) {
        if (text.startsWith(prefix)) {
        return { alignment, text: text.split(prefix)[1] };
        }
    }

    return { alignment: 'start', text };  // Default alignment if no prefix is found
    };

    const sections = preprocessMarkdown(markdown);

    return (
        <Stack style={{width:'100%'}} direction='auto' columnsJustification='space-evenly'>
            {sections.map((section, index) => {
                const { alignment, text } = processText(section.content.trim());
                if (text && text!==''){
                    return(
                        <div key={index} style={{textAlign:alignment, width:'100%'}} >
                            <ReactMarkdown
                                children={text}
                                remarkPlugins={[gfm]}
                                components={{
                                    h1:H1,
                                    p: Paragraph,
                                    a: ButtonRenderer,
                                    table: Table,
                                    thead: TableHead,
                                    tbody: TableBody,
                                    tr: TableRow,
                                    th: ({ node, ...props }) => <TableCell isHeader={true} {...props} />,
                                    td: TableCell,
                                    hr: CustomHr,
                                    img : CustomImg
                                }}
                            />
                        </div>
                    )
                }

        })}{ isModalOpen &&
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                onSubmit={handleSubmit}
                email={email}
                setEmail={setEmail}
                error={error}
            />
            }

        </Stack>
    );
};

export default MarkdownComponent;
