// CustomSaveButton.js
import React from 'react';
import { useNotify, useRedirect, SaveButton } from 'react-admin';

const CacheSaveButton = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = (values) => {
    // Store the changes locally instead of saving immediately
    props.onCollectChanges(values);
    notify('Changes collected locally. Press "Apply Changes" to save.');
  };

  return <SaveButton {...props} handleSubmitWithRedirect={handleSave} />;
};

export default CacheSaveButton;
