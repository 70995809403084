import React from 'react';
import Stack from './Stack';
import HoverButton from './HoverButton';
import MarkdownComponent from './MarkdownComponent';
function Card({ children,image, title,titleLevel = 'h2',titleStyle, description, buttonText, config,onButtonClick,style,imageContainerStyle,imageStyle,columnsJustification,direction='h',scrollable=false,itemsStyle,notificationImage}) {
    const TitleTag = `${titleLevel}`;
    const titleStyles = {
        width: '100%',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 700,
        ...titleStyle
    };
    const hexToRgba = (hex,a) => {
        // Remove the hash if it's present
        hex = hex.replace(/^#/, '');
        // Parse the r, g, b values
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        let ret=  `rgba(${r},${g},${b},${a})`;
        return ret;
    };
    return (
    <div style={{
        minWidth: '200px',
        margin: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
        borderRadius: '30px',
        padding: '10px',
        background: config.colors.background,
        boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
        overflow:'hidden',
        ...style
        }}
        className='CardComponent'>
    <Stack direction={direction} columnsJustification={columnsJustification} style={{justifyContent: columnsJustification,height: '100%'}} enableScrollButtons={scrollable} config={{config}} parentStyle={itemsStyle}>
    {image && (
          <div className='imageContainer' style={{position: 'relative', padding:'5px',width: '100%',display:'flex',...imageContainerStyle }}>
            <img onClick={onButtonClick} src={image} alt={title} style={{
              objectFit: 'cover',

              borderRadius: '15px',
              maxHeight: 'calc(100vh - 300px)',
              margin:'5px', // Ensure the image covers the container
              boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.3), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
              ...imageStyle
            }} />

            <div style={{
              position: 'absolute',
              top: '10px',
              right: '10px'
            }}>
              {notificationImage && <img src={notificationImage} alt="Filter" style={{ height: '50px', width: '50px',borderRadius:'10px',boxShadow: `-2px -2px 5px rgba(149, 208, 255,0.3), 3px 3px 5px rgba(0, 0, 0, 0.3)`, }} />}
            </div>
          </div>
        )}
      <div className='textContainer' style={{ paddingLeft: direction==='h'? '10px':'0px',padding:'10px',display:'flex',flexDirection:'column',justifyContent:"center",alignItems:'center'}}>
        {title && <TitleTag style={{ color: config.colors.accent,whiteSpace: 'pre-wrap',textAlign:'center', marginBottom:'0px',...titleStyles}}>{title}</TitleTag>}
        {description && <MarkdownComponent markdown={description} currentLang={'it'}/>}
        {buttonText && <HoverButton config={config} onClick={onButtonClick}>{buttonText}</HoverButton>}
      </div>
      {children}
    </Stack>
    </div>
  );
}

export default Card;
