// dataProvider.js

import {
    collection,
    doc,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    where,
    orderBy,
    limit,
    startAfter,
    serverTimestamp,
    writeBatch,
    getCountFromServer,
} from 'firebase/firestore';
import { db } from './components/firebase'; // Make sure to import your Firestore instance

const dataProvider = {
    // Get a list of records (with pagination, sorting, and filters)
    // getList: async (resource, params) => {
    //     console.log('getList called with:', resource, params);
    //     const { page, perPage } = params.pagination;
    //     const { field, order } = params.sort;
    //     const filters = params.filter;

    //     let q = collection(db, resource);

    //     // Apply filters
    //     if (filters) {
    //         for (const key in filters) {
    //             const value = filters[key];
    //             if (Array.isArray(value)) {
    //                 // For array values, use 'in' operator
    //                 const chunks = [];
    //                 while (value.length) {
    //                     chunks.push(value.splice(0, 10));
    //                 }
    //                 const queries = chunks.map((chunk) => where(key, 'in', chunk));
    //                 q = query(q, ...queries);
    //             } else {
    //                 q = query(q, where(key, '==', value));
    //             }
    //         }
    //     }

    //     // Apply sorting
    //     if (field && order) {
    //         q = query(q, orderBy(field, order.toLowerCase()));
    //     }

    //     // Pagination using cursors
    //     // Note: Firestore pagination with cursors is complex
    //     // For simplicity, we'll fetch all data and slice it (not efficient for large datasets)
    //     const snapshot = await getDocs(q);
    //     const allData = snapshot.docs.map((docSnap) => ({
    //         id: docSnap.id,
    //         ...docSnap.data(),
    //     }));

    //     const total = allData.length;
    //     const start = (page - 1) * perPage;
    //     const end = page * perPage;
    //     const data = allData.slice(start, end);
    //     console.log('Fetched data:', data);
    //     console.log('Total records:', total);
    //     return {
    //         data,
    //         total,
    //     };
    // },
    getList: async (resource, params) => {
        const collectionRef = collection(db, resource);
        const snapshot = await getDocs(collectionRef);
        const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        const total = data.length;

        return {
            data,
            total,
        };
        // If needed, handle other resources or nested data
        throw new Error(`Unsupported resource: ${resource}`);
    },

    // Get a single record by ID
    getOne: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error(`Document not found: ${params.id}`);
        }

        return {
            data: {
                id: docSnap.id,
                ...docSnap.data(),
            },
        };
    },

    // Get multiple records by IDs
    getMany: async (resource, params) => {
        const ids = [...params.ids]; // Clone the IDs array
        const docs = [];

        while (ids.length) {
            const batchIds = ids.splice(0, 10); // Firestore 'in' queries accept up to 10 IDs
            const q = query(
                collection(db, resource),
                where('__name__', 'in', batchIds)
            );
            const snapshot = await getDocs(q);
            snapshot.forEach((docSnap) => {
                docs.push({
                    id: docSnap.id,
                    ...docSnap.data(),
                });
            });
        }

        return {
            data: docs,
        };
    },

    // Get records related to another record
    getManyReference: async (resource, params) => {
        const { target, id } = params;
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filters = params.filter;

        let q = query(
            collection(db, resource),
            where(target, '==', id)
        );

        // Apply filters
        if (filters) {
            for (const key in filters) {
                const value = filters[key];
                q = query(q, where(key, '==', value));
            }
        }

        // Apply sorting
        if (field && order) {
            q = query(q, orderBy(field, order.toLowerCase()));
        }

        // Fetch documents
        const snapshot = await getDocs(q);
        const allData = snapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
        }));

        const total = allData.length;
        const start = (page - 1) * perPage;
        const end = page * perPage;
        const data = allData.slice(start, end);

        return {
            data,
            total,
        };
    },

    // Create a new record
    create: async (resource, params) => {
        const data = {
            ...params.data,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        };
        const collectionRef = collection(db, resource);
        const docRef = await addDoc(collectionRef, data);
        const docSnap = await getDoc(docRef);

        return {
            data: {
                id: docRef.id,
                ...docSnap.data(),
            },
        };
    },

    // dataProvider.js
    update: async (resource, params) => {
        const { id, ...data } = params.data; // Exclude 'id' from data
        data.updatedAt = serverTimestamp();
        const docRef = doc(db, resource, params.id);
        await updateDoc(docRef, data);
        const docSnap = await getDoc(docRef);

        return {
            data: {
                id: docSnap.id,
                ...docSnap.data(),
            },
        };
    },
    // Update multiple records
    updateMany: async (resource, params) => {
        const batch = writeBatch(db);
        params.ids.forEach((id) => {
            const docRef = doc(db, resource, id);
            const data = {
                ...params.data,
                updatedAt: serverTimestamp(),
            };
            batch.update(docRef, data);
        });

        await batch.commit();

        return {
            data: params.ids,
        };
    },

    // Delete a record
    delete: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        await deleteDoc(docRef);

        return {
            data: { id: params.id },
        };
    },

    // Delete multiple records
    deleteMany: async (resource, params) => {
        const batch = writeBatch(db);
        params.ids.forEach((id) => {
            const docRef = doc(db, resource, id);
            batch.delete(docRef);
        });

        await batch.commit();

        return {
            data: params.ids,
        };
    },
};

export default dataProvider;
