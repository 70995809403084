// Function to log events with i18next locale
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';
import i18next from 'i18next'; // Ensure i18next is correctly initialized

export const logEventWithLocale = (eventName, additionalData = {}) => {
    const locale = i18next.language; // Get the current language from i18next

    // Log the event to Firebase Analytics
    logEvent(analytics, eventName, {
        locale: locale, // Add the current locale
        ...additionalData, // Include any additional data
    });

    console.log(`Logged event: ${eventName}`, { locale, ...additionalData });
};
