import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './components/firebase';
import { useAuth } from './App';  // Import the useAuth hook from the global AuthContext

const authProvider = {
    // Called when the user attempts to log in
    login: async ({ email, password }) => {
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            // Optionally fetch user data from Firestore if needed
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error.message); // Return a rejected promise with an error message
        }
    },
    // Called when the user clicks on the logout button
    logout: async () => {
        const auth = getAuth();
        await signOut(auth);
        return Promise.resolve();
    },
    // Called when the API returns an error
    checkError: (error) => {
        return Promise.resolve();
    },
    // Called when the user navigates to a new location
    checkAuth: () => {
        const auth = getAuth();
        return new Promise((resolve, reject) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe();
                if (user) {
                    resolve();
                } else {
                    reject({ redirectTo: '/admin/login' });
                }
            });
        });
    },
    // Used to retrieve the user's permissions
    getPermissions: async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                return Promise.resolve(userData.role); // Return the role
            } else {
                return Promise.reject('No user data');
            }
        } else {
            return Promise.reject('No user logged in');
        }
    },
};

export default authProvider;
