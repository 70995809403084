import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../assets/config'; // Import the config file
// import gb from '../assets/locales/flags/gb.png'; // Import the config file
// import it from '../assets/locales/flags/it.png'; // Import the config file
// import fr from '../assets/locales/flags/fr.png'; // Import the config file
// import es from '../assets/locales/flags/de.png'; // Import the config file
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

function LanguageSwitcher() {
    const { i18n } = useTranslation();
    // Define a default options array with language data

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const locales = config.locales.map(locale => {
        return {
            id: locale.id,
            label: locale.name,
            image: locale.icon
        };
    });
    const handleSelect = (selection) => {
        const index = config.locales.findIndex(r => r.id === selection.id);
        const selectionItem = config.locales[index];
        changeLanguage(selectionItem.id);
    };

    return (
        <div className="flex space-x-4 justify-center items-center mt-4">
            {false && (config.locales || []).map((locale,index) => (
                <button key={index} onClick={() => changeLanguage(locale.id)} className="rounded-full overflow-hidden w-10 h-10 shadow-md">
                <img src={locale.icon} alt= {locale.name} className="w-full h-full object-cover" />
                </button>
            )
            )}
            <Selector isMobile={false} options={locales} onSelect={handleSelect} showTextOnSelect={false} initialSelectionIndex={locales.findIndex(r=>i18n.language.startsWith(r.id))}/>
        </div>
    );
}





function Selector({ isMobile, options, CapsuleStyle,onSelect,ActiveButtonStyle, showTextOnSelect = true,initialSelectionIndex = 0 }) {
    // const options = [
    //     {
    //     id: 'en',
    //     name: 'English',
    //     icon: gb
    //     },
    //     {
    //     id: 'it',
    //     name: 'Italiano',
    //     icon: it,
    //     },
    //     {
    //     id: 'es',
    //     name: 'Español',
    //     icon: es,
    //     },
    //     {
    //     id: 'fr',
    //     name: 'Français',
    //     icon: fr,
    //     },
    //     {
    //     id: 'de',
    //     name: 'Deutsch',
    //     icon: de,
    //     }
    // ];
    const { t } = useTranslation();
    const refs = useRef(options.map(() => React.createRef()));
    const [capsuleStyle, setCapsuleStyle] = useState();
    // const [selectedId, setSelectedId] = useState(() => {
    //     if (options && options.length > 0 && initialSelectionIndex < options.length) {
    //       return options[initialSelectionIndex].id;
    //     }
    //     return 0; // Fallback value if options is undefined or out of bounds
    //   });
      const [selectedId, setSelectedId] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(initialSelectionIndex);
    const buttonStyle = {
        padding:'10px',
        width:'100%',
        fontSize: '16px',
        color: 'rgb(29, 59, 82)',
        backgroundColor: 'rgba(0,0,0,0)',
        border: 'none',
        borderRadius: '20px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'color 0.3s ease',  // Smooth transition for background color
    };

    const activeButtonStyle = {
        ...buttonStyle,
        color: 'white' ,
        ...ActiveButtonStyle // And perhaps a different text color
    };

    useEffect(() => {
        updateCapsulePosition(selectedId);
    }, [selectedId, isMobile,selectedIndex]);

    const handleSelection = (selection) => {
        updateCapsulePosition(options.findIndex(r => r.id === selection.id));
        onSelect(selection);
        setSelectedId(selection.id);
        setSelectedIndex(options.findIndex(r => r.id === selection.id));
    };

    const updateCapsulePosition = (selectionIndex) => {
        const ref = refs.current[selectionIndex];

        if (ref && ref.current) {
            const { offsetWidth, offsetLeft, offsetTop } = ref.current;
            setCapsuleStyle({
                width: `${offsetWidth}px`,
                left: isMobile ? '' : `${offsetLeft}px`,
                top: isMobile ? `${offsetTop}px` : '',
            });
        }
    };
    useEffect(() => {
        // Run this once when the component mounts
        setSelectedIndex(initialSelectionIndex);
        updateCapsulePosition(initialSelectionIndex);
    }, []);
    const standardTail = `
    absolute
    p-[10px] rounded-full
    items-center
    text-white
    shadow-[-2px_-2px_5px_rgba(214,_255,_111,_0.2),_2px_5px_5px_rgba(79,_94,_41,_0.4)]
    transition-all
    bg-secondary
    enabled:hover:shadow-[inset_-2px_-2px_5px_rgba(214,_255,_111,_1),inset_2px_2px_5px_rgba(79,_94,_41,_0.4)]
    enabled:hover:text-white
    disabled:bg-slate-400
    disabled:cursor-not-allowed
    transition
    duration-700
    ease-in-out
    active:translate-y-1
    motion-reduce:transition-none
`;
    return (
        <div className={`flex
        ${isMobile ? 'flex-col' : 'flex-row'} min-w-[200px]
        justify-center items-center transition-all duration-300 rounded-[25px] relative
        shadow-[inset_-2px_-2px_5px_rgba(149,_208,_255,0.3),inset_2px_2px_5px_rgba(0,_0,_0,_0.4)]`} >
            {true && (
                <motion.button
                    className={standardTail}
                    style={{ ...capsuleStyle, ...CapsuleStyle }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    onClick={() => onSelect}
                    >

                    {showTextOnSelect && options[selectedIndex].label}
                    {(!showTextOnSelect && options[selectedIndex] && (
                    <>
                        {options[selectedIndex].icon && (
                        <FontAwesomeIcon className="px-5" icon={options[selectedIndex].icon} />
                        )}
                        {options[selectedIndex].image && (
                        <img src={options[selectedIndex].image} alt={options[selectedIndex].label} className="w-5 h-5 object-cover rounded-full" />
                        )}
                    </>
                    ))}
                </motion.button>
            )}
            {options.map((option, index) => (
                <button
                    key={index}
                    ref={refs.current[index]}
                    className='text-[16px]  min-h-[44px]'
                    style={selectedId === option.id ? activeButtonStyle : buttonStyle}
                    onClick={() => handleSelection(option)}
                >
                    {(!option.icon && !option.image) && t(option.label)}
                    {(option.icon && <FontAwesomeIcon className="px-5" icon={option.icon} />)}
                    {(option.image && <img src={option.image} alt={option.label} className="w-5 h-5 object-cover rounded-full" />)}


                </button>
            ))}
        </div>
    );
}



export default LanguageSwitcher;
