import React from 'react';
import {
    SimpleForm,
    TextInput,
    BooleanInput,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    DateField,
    SelectInput,
    useNotify,
    useRedirect,
    SaveButton,
    Toolbar,
    Edit,
    useRecordContext,
    useDataProvider,
    required
} from 'react-admin';
import { useForm } from 'react-hook-form';

// Custom Toolbar with Save Button
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const UserProfileEdit = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const { handleSubmit } = useForm();

    // Submit handler to save user data
    const handleSave = async (values) => {
        try {
            await dataProvider.update('users', {
                id: record.id,
                data: values,
                previousData: record
            });
            notify('User information updated successfully', { type: 'success' });
            redirect('/');
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'warning' });
        }
    };

    return (
        <Edit title="User Profile" record={record}>
            <SimpleForm onSubmit={handleSubmit(handleSave)} toolbar={<CustomToolbar />}>
                <TextInput source="username" label="Username" validate={required()} fullWidth />
                <TextInput source="name" label="Name" validate={required()} fullWidth />
                <TextInput source="surname" label="Surname" validate={required()} fullWidth />
                <TextInput source="email" label="Email" validate={required()} fullWidth disabled={!record.email_verified} />
                <BooleanInput source="email_verified" label="Email Verified" disabled fullWidth />

                <TextInput source="preferences.language" label="Language" fullWidth />
                <SelectInput source="preferences.theme" label="Theme" choices={[
                    { id: 'light', name: 'Light' },
                    { id: 'dark', name: 'Dark' }
                ]} fullWidth />

                <ArrayInput source="accessible_projects" label="Accessible Projects">
                    <SimpleFormIterator>
                        <TextInput source="" label="Project ID" />
                    </SimpleFormIterator>
                </ArrayInput>

                {/* Address */}
                <TextInput source="address.street" label="Street" fullWidth />
                <TextInput source="address.city" label="City" fullWidth />
                <TextInput source="address.state" label="State" fullWidth />
                <TextInput source="address.postal_code" label="Postal Code" fullWidth />
                <TextInput source="address.country" label="Country" fullWidth />

                {/* Account Stats */}
                <BooleanInput source="is_active" label="Is Active" fullWidth />
                <BooleanInput source="is_blocked" label="Is Blocked" fullWidth />
                <DateField source="account_stats.created_at" label="Account Created At" showTime fullWidth />
                <DateField source="last_login" label="Last Login" showTime fullWidth />
            </SimpleForm>
        </Edit>
    );
};

export default UserProfileEdit;
